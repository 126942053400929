import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

interface CoerceFunction<Value = string> {
  (value: string): Value
}

interface QueryParams<Value = any> { // eslint-disable-line @typescript-eslint/no-explicit-any
  [key: string]: Value
}

export function paramsToQueryString(params: QueryParams = {}): string {
  const query = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
  return query ? `?${query}` : '';
}

export function queryStringToParams<Value = string>(query = '', coerce?: CoerceFunction<Value>): QueryParams<Value> {
  if (!query) {
    return {};
  }
  if (query[0] === '?') {
    query = query.slice(1);
  }
  return query.split('&').reduce((params, keyval) => {
    let [key, value = 'true'] = keyval.split('=');
    // decode each side after split because either could contain =
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);
    return { ...params, [key]: coerce ? coerce(value) : value };
  }, {});
}

export function getUrlQueryParams(): { [key: string]: string } {
  return queryStringToParams(window.location.hash.split('?')[1]);
}

export function getSameOriginUrl(href: string): string {
  // rewrite urls to use the same origin
  const treatedUrl = new URL(window.location.href);
  const linkUrl = new URL(href, window.location.href);
  treatedUrl.hash = linkUrl.hash;
  return treatedUrl.toString();
}

export function deconstructUrl(url: string): { url: string, params: { [key: string]: string } } {
  const [base, search] = url.split('?');
  return { url: base, params: queryStringToParams(search) };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export function omitEmptyParams<T extends unknown>(params: Record<string, T>): Record<string, T> {
  return omitBy(params, value => isNil(value) || value === '');
}
